import React, { createContext, useState } from 'react';
import { CalculationHistory, ResultOperation, ResultRow } from '../types';
import { ResultsContextType } from '../types/contexts';

export const ResultsContext = createContext<ResultsContextType>({
 setResultRows: () => null,
 resultRows: [],
 setFinalResultValue: () => null,
 finalResultValue: 0,
 setFinalResultUnit: () => null,
 finalResultUnit: '',
 setResultOperations: () => null,
 resultOperations: [],
 hasMultipleResults: false,
 setHasMultipleResults: () => null,
 resultArray: [],
 setResultArray: () => null,
 setResultsCalculationHistory: () => null,
});

interface ResultsProviderProps {
 children: React.ReactElement;
}

export function ResultsProvider({
 children,
}: ResultsProviderProps): React.ReactElement {
 const [resultRows, setResultRows] = useState<Array<ResultRow>>([]);

 const [resultOperations, setResultOperations] = useState<
  Array<ResultOperation>
 >([]);

 const [finalResultValue, setFinalResultValue] = useState(0);
 const [finalResultUnit, setFinalResultUnit] = useState('');
 const [hasMultipleResults, setHasMultipleResults] = useState(false);
 const [resultArray, setResultArray] = useState<Array<ResultRow>>([]);
 const [resultsCalculationHistory, setResultsCalculationHistory] = useState<
  CalculationHistory | undefined
 >();

 return (
  <ResultsContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    resultRows,
    setResultRows,
    finalResultValue,
    setFinalResultValue,
    finalResultUnit,
    setFinalResultUnit,
    resultOperations,
    setResultOperations,
    hasMultipleResults,
    setHasMultipleResults,
    resultArray,
    setResultArray,
    resultsCalculationHistory,
    setResultsCalculationHistory,
   }}
  >
   {children}
  </ResultsContext.Provider>
 );
}
