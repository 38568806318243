import { Flex, HStack, VStack, Text, Image } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { To, useNavigate } from 'react-router-dom';
import Question from '../../../components/QuestionComponent';

import imageDraper from '../../../assets/icons/icon-draper.svg';
import imageMikstur from '../../../assets/icons/icon-mikstur.svg';

import { LiquidRoutes } from '../../../types';

import CalculatorNavigationComponent from '../../../components/CalculatorComponent/CalculatorNavigationComponent';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { InjectionContext } from '../../../contexts/InjectionProvider';
import Header from '../../../components/HeaderComponent';

function LiquidHomePage(): React.ReactElement {
 const navigate = useNavigate();

 const { setBackButtonAction, setBackButtonText, setHomeButtonAction } =
  useContext(CalculatorNavigationContext);

 const handleClick = (path: To) => {
  navigate(path);
 };

 const { homeRedirect } = useContext(InjectionContext);

 useEffect(() => {
  setBackButtonAction(homeRedirect);
  setBackButtonText('Avbryt');
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Flex flexDirection="column" w="100%">
   <CalculatorNavigationComponent hasNextButton={false} />
   <Flex w="100%" py="30px" px="30px">
    <Header />
   </Flex>
   <Flex flexDirection="column" px="30px" pt="30px" w="100%" h="100%">
    <Question
     title="Flytende"
     question="I hvilken form skal legemiddelet gis?"
    />
    <VStack mt="auto" bg="gray.400" mx="-30px" px="40px">
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="black.500"
      spacing="30px"
     >
      <Image src={imageDraper} w="32px" />
      <Text
       fontWeight="600"
       fontSize="24"
       onClick={() => handleClick(LiquidRoutes.Draper)}
       w="100%"
      >
       Dråper
      </Text>
     </HStack>
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      spacing="30px"
      onClick={() => handleClick(LiquidRoutes.Mikstur)}
     >
      <Image src={imageMikstur} w="32px" />
      <Text fontWeight="600" fontSize="24" w="100%">
       Mikstur
      </Text>
     </HStack>
    </VStack>
   </Flex>
  </Flex>
 );
}

export default LiquidHomePage;
