import React, { useContext } from 'react';
import { VStack, Flex } from '@chakra-ui/react';

import AppRoutes from './routes';

import InfoComponent from './components/InfoComponent';

import { AppContext } from './contexts/AppProvider';

function App(): React.ReactElement {
 const { showInfoAlert } = useContext(AppContext);

 return (
  <VStack minHeight="100vh" flex={1} spacing={0} justifyContent="space-between">
   <Flex minHeight="82vh" w="100%">
    {!showInfoAlert ? <AppRoutes /> : <InfoComponent />}
   </Flex>
  </VStack>
 );
}

export default App;
