import React, { useContext, useEffect } from 'react';
import { To, useNavigate } from 'react-router-dom';

import { Flex, HStack, VStack, Text, Image } from '@chakra-ui/react';

import Question from '../../../components/QuestionComponent';
import CalculatorNavigationComponent from '../../../components/CalculatorComponent/CalculatorNavigationComponent';

import dilutionAdditionVolumeIcon from '../../../assets/icons/icon-dilution-addtion-volume.svg';
import dilutionStrengthDilutedIcon from '../../../assets/icons/icon-dilution-strength-diluted.svg';

import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { DilutionAdditionVolumeContext } from '../../../contexts/DilutionProvider/DilutionAdditionVolumeProvider';
import { DilutionRoutes } from '../../../types';
import Header from '../../../components/HeaderComponent';

function DilutionHomePage(): React.ReactElement {
 const navigate = useNavigate();

 const { setBackButtonAction, setBackButtonText, setHomeButtonAction } =
  useContext(CalculatorNavigationContext);

 const handleClick = (path: To) => {
  navigate(path);
 };

 const { homeRedirect } = useContext(DilutionAdditionVolumeContext);

 useEffect(() => {
  setBackButtonAction(homeRedirect);
  setBackButtonText('Avbryt');
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 return (
  <Flex flexDirection="column" w="100%">
   <CalculatorNavigationComponent hasNextButton={false} />
   <Flex w="100%" py="30px" px="30px">
    <Header />
   </Flex>
   <Flex flexDirection="column" px="30px" pt="30px" w="100%" h="100%">
    <Question question="Hva skal regnes ut?" />
    <VStack mt="auto" bg="gray.400" mx="-30px" px="40px">
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="black.500"
      spacing="30px"
     >
      <Image w="32px" src={dilutionAdditionVolumeIcon} />
      <Text
       fontWeight="600"
       fontSize="24"
       onClick={() => handleClick(DilutionRoutes.AdditionVolume)}
       w="100%"
      >
       Tilsetningsvolum
      </Text>
     </HStack>
     <HStack
      w="100%"
      h="130px"
      alignItems="center"
      spacing="30px"
      onClick={() => handleClick(DilutionRoutes.StrengthDiluted)}
     >
      <Image w="32px" src={dilutionStrengthDilutedIcon} />
      <Text fontWeight="600" fontSize="24" w="100%">
       Styrke, fortynnet
      </Text>
     </HStack>
    </VStack>
   </Flex>
  </Flex>
 );
}

export default DilutionHomePage;
