import React, { useContext } from 'react';
import { HStack, Button, Icon, Flex, Text } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { ResultsContext } from '../../../contexts/ResultsProvider';
import { saveNewHistory } from '../../../utils';

interface CalculatorNavigationComponentProps {
 hasNextButton?: boolean;
 saveHistory?: boolean;
}

function CalculatorNavigationComponent({
 hasNextButton,
 saveHistory,
}: CalculatorNavigationComponentProps): React.ReactElement {
 const {
  backButtonText,
  backButtonAction,
  nextButtonDisabled,
  nextButtonText,
  nextButtonAction,
  homeButtonAction,
 } = useContext(CalculatorNavigationContext);

 const { resultsCalculationHistory } = useContext(ResultsContext);

 const onClickBackButton = () => {
  if (backButtonAction) {
   backButtonAction();
  }
 };

 const onClickNextButton = () => {
  if (nextButtonAction) {
   nextButtonAction();
  }
  if (saveHistory) {
   saveNewHistory(resultsCalculationHistory);
  }
 };

 const onClickHomeButton = () => {
  if (homeButtonAction) {
   homeButtonAction();
  }
 };

 return (
  <HStack
   pos="sticky"
   top="0"
   zIndex="1"
   w="100%"
   minHeight="70px"
   bg="gray.500"
   paddingRight="0"
   paddingLeft="0"
   spacing={0}
  >
   <Button
    type="button"
    maxW="70px"
    w="50%"
    h="100%"
    bg="blue.900"
    fontSize="27px"
    borderRadius="0"
    onClick={onClickHomeButton}
   >
    <Flex direction="column" align="center" justify="center" gap="4px">
     <Icon viewBox="0 0 27 27">
      <path
       d="M14.0974 1.93387C13.9391 1.77569 13.7246 1.68683 13.5008 1.68683C13.2771 1.68683 13.0625 1.77569 12.9043 1.93387L2.77931 12.0589C2.7007 12.1372 2.63833 12.2303 2.59576 12.3328C2.5532 12.4353 2.53127 12.5452 2.53125 12.6562V24.4687C2.53125 24.6925 2.62014 24.9071 2.77838 25.0654C2.93661 25.2236 3.15122 25.3125 3.375 25.3125H10.9688C11.1925 25.3125 11.4071 25.2236 11.5654 25.0654C11.7236 24.9071 11.8125 24.6925 11.8125 24.4687V17.7187H15.1875V24.4687C15.1875 24.6925 15.2764 24.9071 15.4346 25.0654C15.5929 25.2236 15.8075 25.3125 16.0312 25.3125H23.625C23.8488 25.3125 24.0634 25.2236 24.2216 25.0654C24.3799 24.9071 24.4688 24.6925 24.4688 24.4687V12.6562C24.4689 12.5454 24.4473 12.4355 24.405 12.333C24.3627 12.2305 24.3007 12.1374 24.2224 12.0589L21.9375 9.77568V4.21874C21.9375 3.99497 21.8486 3.78035 21.6904 3.62212C21.5321 3.46389 21.3175 3.37499 21.0938 3.37499H19.4062C19.1825 3.37499 18.9679 3.46389 18.8096 3.62212C18.6514 3.78035 18.5625 3.99497 18.5625 4.21874V6.40068L14.0974 1.93387ZM4.21875 23.625V13.0056L13.5 3.7243L22.7812 13.0056V23.625H16.875V16.875C16.875 16.6512 16.7861 16.4366 16.6279 16.2784C16.4696 16.1201 16.255 16.0312 16.0312 16.0312H10.9688C10.745 16.0312 10.5304 16.1201 10.3721 16.2784C10.2139 16.4366 10.125 16.6512 10.125 16.875V23.625H4.21875Z"
       fill="white"
      />
     </Icon>
     <Text fontSize="14px" color="#ffffff">
      Hjem
     </Text>
    </Flex>
   </Button>
   <Button
    type="button"
    onClick={onClickBackButton}
    w={hasNextButton ? '50%' : '100%'} // Set the width conditionally
    h="100%"
    bg="gray.400"
    fontSize="20px"
    borderRadius="0"
   >
    <AiOutlineArrowLeft size={28} /> {`${backButtonText || 'Avbryt'}`}
   </Button>
   {hasNextButton && (
    <Button
     w="50%"
     h="100%"
     bg="gray.400"
     onClick={onClickNextButton}
     disabled={nextButtonDisabled}
     type="button"
     fontSize="20px"
     borderRadius="0"
    >
     {`${nextButtonText || 'Neste'}`} <AiOutlineArrowRight size={28} />
    </Button>
   )}
  </HStack>
 );
}

CalculatorNavigationComponent.defaultProps = {
 hasNextButton: true,
 saveHistory: false,
};

export default CalculatorNavigationComponent;
