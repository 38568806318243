/* eslint-disable no-alert */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { To, useNavigate } from 'react-router-dom';

import {
 Flex,
 HStack,
 VStack,
 Text,
 Image,
 AlertDialog,
 AlertDialogHeader,
 AlertDialogBody,
 AlertDialogOverlay,
 AlertDialogFooter,
 Button,
 AlertDialogContent,
} from '@chakra-ui/react';

import Question from '../../../components/QuestionComponent';
import CalculatorNavigationComponent from '../../../components/CalculatorComponent/CalculatorNavigationComponent';

import iconInfusionAddedDrug from '../../../assets/icons/icon-infusion-added-drug.svg';
import iconInfusionWithoutAdditive from '../../../assets/icons/icon-infusion-without-additive.svg';
import iconInfusionPerTime from '../../../assets/icons/icon-infusion-per-time.svg';

import { CalculatorNavigationContext } from '../../../contexts/CalculatorNavigationProvider';
import { DilutionAdditionVolumeContext } from '../../../contexts/DilutionProvider/DilutionAdditionVolumeProvider';
import { InfusionRoutes } from '../../../types';
import Header from '../../../components/HeaderComponent';

function InfusionHomePage(): React.ReactElement {
 const navigate = useNavigate();
 const cancelRef = useRef<any>();
 const [openDialog, setOpenDialog] = useState<boolean>(false);
 const { setBackButtonAction, setBackButtonText, setHomeButtonAction } =
  useContext(CalculatorNavigationContext);

 const handleClick = (path: To) => {
  if (path === InfusionRoutes.PerTime) {
   setOpenDialog(true);
  } else {
   navigate(path);
  }
 };

 const onClosePerTimeAlert = () => {
  navigate(InfusionRoutes.PerTime);
 };

 const { homeRedirect } = useContext(DilutionAdditionVolumeContext);

 useEffect(() => {
  setBackButtonAction(homeRedirect);
  setBackButtonText('Avbryt');
  setHomeButtonAction(homeRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);
 return (
  <>
   <Flex flexDirection="column" w="100%">
    <CalculatorNavigationComponent hasNextButton={false} />
    <Flex w="100%" py="30px" px="30px">
     <Header />
    </Flex>
    <Flex flexDirection="column" px="30px" w="100%" h="100%">
     <Question title="Infusjon" question="Regn ut hastigheten for infusjon:" />
     <VStack mt="auto" bg="gray.400" mx="-30px" px="40px">
      <HStack
       w="100%"
       h="130px"
       alignItems="center"
       borderBottom="1px solid"
       borderColor="black.500"
       spacing="30px"
      >
       <Image w="32px" src={iconInfusionAddedDrug} />
       <Text
        fontWeight="600"
        fontSize="24"
        onClick={() => handleClick(InfusionRoutes.AddedDrug)}
        w="100%"
       >
        Tilsatt legemiddel
       </Text>
      </HStack>
      <HStack
       w="100%"
       h="130px"
       alignItems="center"
       borderBottom="1px solid"
       borderColor="black.500"
       spacing="30px"
      >
       <Image w="28px" ml="4px" src={iconInfusionWithoutAdditive} />
       <Text
        fontWeight="600"
        fontSize="24"
        onClick={() => handleClick(InfusionRoutes.WithoutAdditive)}
        w="100%"
       >
        Uten tilsetning
       </Text>
      </HStack>
      <HStack
       w="100%"
       h="130px"
       alignItems="center"
       spacing="30px"
       onClick={() => handleClick(InfusionRoutes.PerTime)}
      >
       <Image w="32px" src={iconInfusionPerTime} />
       <Text fontWeight="600" fontSize="24px" w="100%">
        Når dosen er angitt pr minutt eller time
       </Text>
      </HStack>
     </VStack>
    </Flex>
   </Flex>
   <AlertDialog
    isOpen={openDialog}
    onClose={onClosePerTimeAlert}
    leastDestructiveRef={cancelRef}
   >
    <AlertDialogOverlay>
     <AlertDialogContent>
      <AlertDialogHeader>Husk</AlertDialogHeader>
      <AlertDialogBody>
       Infusjonsposens styrke må være kjent på forhånd.
      </AlertDialogBody>
      <AlertDialogFooter>
       <Button onClick={onClosePerTimeAlert}>Ok</Button>
      </AlertDialogFooter>
     </AlertDialogContent>
    </AlertDialogOverlay>
   </AlertDialog>
  </>
 );
}

export default InfusionHomePage;
