import React, { createContext, useEffect, useState } from 'react';

import { AppContextType } from '../types/contexts';
import { BooleanType } from '../types';

export const AppContext = createContext<AppContextType>({
 setShowInfoAlert: () => null,
 showInfoAlert: true,
});

interface AppProviderProps {
 children: React.ReactElement;
}

export function AppProvider({
 children,
}: AppProviderProps): React.ReactElement {
 const [showInfoAlert, setShowInfoAlert] = useState(true);

 useEffect(() => {
  const storedShowInfoAlert = localStorage.getItem('showInfoAlert');
  if (
   storedShowInfoAlert === null ||
   storedShowInfoAlert === BooleanType.true
  ) {
   setShowInfoAlert(true);
  } else {
   setShowInfoAlert(false);
  }
 }, []);

 return (
  <AppContext.Provider
   // eslint-disable-next-line react/jsx-no-constructed-context-values
   value={{
    showInfoAlert,
    setShowInfoAlert,
   }}
  >
   {children}
  </AppContext.Provider>
 );
}
